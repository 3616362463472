import api from '../services/api'
import Helper from './helper'

class SizeService {
  get(p, s) {
    return api.get(`/s?${Helper.getPageQuery(p, s)}`)
  }

  create(row) {
    return api.post('/s', row)
  }

  update(row) {
    return api.put(`/s/${row.id}`, row)
  }

  delete(row) {
    return api.delete(`/s/${row.id}`)
  }
}

export default new SizeService()
